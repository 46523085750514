<script>  
export default {  
  data() {  
    // gon.watch('gifters', {interval: 5000}, this.updateTimer);
    // gon.watch('paused', {interval: 5000}, this.updatePaused);
    // gon.watch('paused_time_string', {interval: 5000}, this.updatePauseTime);
    // gon.watch('totalPause', {interval: 5000}, this.updateTotalPause);
    gon.watch('all_vars', {interval: 5000}, this.updateAll)
    this.gifters = gon.gifters
    setInterval(this.updateTimer, 500, null)
    var time = this.computeTime(gon.gifters)
    var curr = new Date()
    var start = new Date('January 24, 2025, 18:00:00-05:00')
    var offset = 4*60*60 //starting time
    var paused = gon.paused
    var pauseTime = new Date(gon.pause_time_string)
    var totalPause = gon.totalPause
    var endTime = new Date('January 24, 2026, 21:00:00-03:00')
   return { 
    startTime: start,
    timeInSeconds: this.computeTime(gon.gifters), 
    currTime: curr,
    timeString: this.createTimeString(time, curr, start, offset, endTime),
    offset: offset,
    endTime: endTime,
    paused: paused,
    pauseTime: pauseTime,
    totalPause: totalPause,
    gifters: gon.gifters
   }
  },
   methods: {
    updateAll(all_vars) {
        // console.log("here")
        // console.log(all_vars)
        this.updateTimer(all_vars.gifters)
        this.updatePaused(all_vars.paused)
        this.updatePauseTime(all_vars.paused_time_string)
        this.updateTotalPause(all_vars.totalPause)
    },
    updateTimer(newGifters) {
        if (newGifters === null) {
            newGifters = this.gifters
        } else {
            this.gifters = newGifters
        }
        // console.log("updating timer")
        // console.log(newGifters)
        this.timeInSeconds = this.computeTime(newGifters)
        // console.log(this.timeInSeconds)
        this.currTime = new Date()
        this.timeString = this.createTimeString(this.timeInSeconds, this.currTime, this.startTime, this.offset, this.endTime, this.paused, this.pauseTime, this.totalPause)
        // console.log(this.timeString)
        // console.log(this.totalPause)
    },
    updatePaused(paused) {
        this.paused = paused
        this.currTime = new Date()
        // this.timeString = this.createTimeString(this.timeInSeconds, this.currTime, this.startTime, this.offset, this.endTime, this.paused, this.pauseTime, this.totalPause)
    },
    updatePauseTime(paused_time_string) {
        var newPauseString = paused_time_string.replace(/\.\d+/g, "").replace(/ \+0000/, "-00:00")
        // newPauseString = "2023/01/31 05:58:36-00:00"
        this.pauseTime = Date.parse(newPauseString)
        // this.pauseTime +=
        // alert(paused_time_string)
        this.currTime = new Date()
        this.timeString = this.createTimeString(this.timeInSeconds, this.currTime, this.startTime, this.offset, this.endTime, this.paused, this.pauseTime, this.totalPause)
    },
    updateTotalPause(totalPause) {
        this.totalPause = totalPause
        this.currTime = new Date()
        this.timeString = this.createTimeString(this.timeInSeconds, this.currTime, this.startTime, this.offset, this.endTime, this.paused, this.pauseTime, this.totalPause)
    },
    computeTime(gifters) {
        var time = 0
        for (const gifter of gifters) {
            time += 4*60*gifter.t1 + 8*60*gifter.t2 + 20*60*gifter.t3 + 1.5*60*Math.floor(gifter.bits/100)
            if(gifter.t1 + gifter.t2 + gifter.t3 >= 5) {
                time +=  5*60
            }
        }
        return time
    }, 
    createTimeString(timeInSeconds, currTime, startTime, offset, endTime, paused, pauseTime, totalPause) {
        var ms = ( 1000*timeInSeconds + 1000*offset - (currTime - startTime)) + totalPause*1000
        var pausedTimeMs = Math.max(0, (currTime - pauseTime))
        if(paused) {
            ms += pausedTimeMs
        }
        if((currTime.getTime() + ms) > endTime.getTime()) {
            ms = Math.max(0, endTime - currTime);
        }
        if (currTime < startTime) {
            ms = startTime - currTime;
        }
        ms = Math.max(0, ms)
        let seconds = ms / 1000;
        // 2- Extract hours:
        const hours = Math.trunc( seconds / 3600 ); // 3,600 seconds in 1 hour
        seconds = seconds % 3600; // seconds remaining after extracting hours
        // 3- Extract minutes:
        const minutes =  Math.trunc( seconds / 60 ).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}); // 60 seconds in 1 minute
        // 4- Keep only seconds not extracted to minutes:
        seconds =  Math.trunc(seconds % 60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        console.log("DUMP")
        // console.log(hours)
        // console.log(seconds)
        // console.log(minutes)
        // console.log(ms)
        // console.log(timeInSeconds)
        // console.log(currTime)
        // console.log(startTime)
        console.log(pausedTimeMs)
        return hours + ":" + minutes + ":" + seconds
    }
   }  
  }  
  
</script>  
  
<template>  
<div class="square rounded p-3 w-25 text-center text-light mx-auto" style="background-color:#335AC3">
    <div class="mx-auto" style="font-size:3.5vw;-webkit-filter: blur(0.000003px)">  {{timeString}} </div>
</div>
</template>  
